<template>
  <div class="body">
    <b-form>
      <b-form-group class="form-element">
        <div class="g-row mb-0 mb-sm-3">
          <div class="g-col-1 d-flex mb-3 mb-sm-0">
            <b-form-select
              @change="productSelected($event)"
              :options="optionProducts"
              class="form-control col-left-input g-select flex-0 mr-3"
            >
              <template slot="first">
                <option :value="null" disabled>{{ i18n['profile-contact-info'].tcSelectProduct }}</option>
              </template>
            </b-form-select>
          </div>
        </div>
        <div class="g-row mb-0 mb-sm-3" v-for="(visit, index) in availableProducts" :key="index" :obj="visit">
          <div class="g-col-1 mb-3 mb-sm-0 d-flex align-items-center">
            <span class="flex-0">
              <strong>{{ visit.product_name }}</strong>
            </span>
            <b-form-input
              :id="'typenumber' + index"
              @change="$emit('restocking-products-selected', products)"
              v-model="visit.quantity_placed"
              class="flex-0 ml-3"
              type="number"
              min="1"
            ></b-form-input>
            <b-button
              variant="tertiary"
              @click="removeProduct(index)"
              class="flex-1 ml-3 d-block w-100-sd btn-pl-xsm btn-text-sm"
              >{{ i18n['profile-contact-info'].tcRemove }}</b-button
            >
          </div>
          <hr />
        </div>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import camera from '@/assets/svgs/camera.vue'

/* eslint-disable */
export default {
  name: 'profile-contact-info',
  props: {
    list: { type: Array },
    i18n: {
      type: Object,
      default: () => {
        return {
          'profile-contact-info': {
            tcRemove: 'Remove',
            tcSelectProduct: 'Select Product',
          },
        }
      },
    },
  },
  data() {
    return {
      number1: '',
      products: [],
    }
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      savePhoto: 'membership/savePhoto',
    }),
    productSelected(evt) {
      let product = this.list.find((product) => product.prd_key == evt)
      if (this.products.find((prod) => prod.prod_key == product.prd_key) != null) {
        return
      }
      this.products.push({
        plc_key: product.plc_key,
        prod_key: product.prd_key,
        delete: false,
        quantity_placed: product.plc_quantity_placed,
        product_name: product.product_name,
      })
      this.$emit('restocking-products-selected', this.products)
    },
    removeProduct(index) {
      let productIndex = this.products.findIndex((product) => {
        return product.prod_key == this.availableProducts[index].prod_key
      })
      this.products[productIndex].delete = true

      this.$emit('restocking-products-selected', this.products)
    },
    buildProductsFromList() {
      let selectedProd = this.list
        .filter((pf) => !!pf.plc_quantity_placed)
        .map((pm) => {
          return {
            plc_key: pm.plc_key,
            prod_key: pm.prd_key,
            quantity_placed: pm.plc_quantity_placed,
            delete: pm.plc_delete_flag,
            product_name: pm.product_name,
          }
        })

      this.products = selectedProd
    },
  },
  created() {
    this.buildProductsFromList()
  },
  computed: {
    ...mapGetters({
      userIndKey: 'user/userId',
    }),
    optionProducts() {
      return this.list.map((product) => {
        return { value: product.prd_key, text: product.product_name }
      })
    },
    availableProducts() {
      return this.products.filter((fltr) => !fltr.delete)
    },
  },
  watch: {
    list() {
      this.buildProductsFromList()
    },
  },
  components: {
    camera: camera,
  },
}
</script>

<style lang="scss" scoped>
</style>
