<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">{{ translations.tcAddEditGideoncardRestockingVisit }}</h1>
    </page-header>
    <page-body class>
      <section class="section-1 bg-block mb-4">
        <h4 v-if="this.visit.location_org_key">
          {{ location.org_name }}
          <div class="change-link" @click="showFacilityDropdown">{{ translations.tcChange }}</div>
        </h4>
        <h1 class="sm mb-4">{{ translations.tcAddEditGideoncardRestockingVisit }}</h1>
        <div v-if="!this.visit.location_org_key" class="col-6">
          <div class="date-select-1 mb-1">
            <label class="font-style-1">{{ translations.tcSelectAFacility }}</label>
          </div>
          <QuickJump @facility_changed="handleFacilityChange()" :i18n="translations.components"></QuickJump>
        </div>
        <div class="body" v-if="this.visit.location_org_key">
          <b-form>
            <b-form-group class="form-element">
              <div class="date-select-1 mb-1">
                <h3 class="font-style-2">{{ translations.tcDate }} *</h3>
              </div>
              <div class="date-select-1 mb-3">
                <b-form-datepicker
                  id="visitDate"
                  v-model="visit.date"
                  class="mb-2 contact_date"
                  :locale="prefCulture"
                  :label-help="translations.tcCalHelp"
                  :label-no-date-selected="translations.tcDatePickerPlaceholder"
                  :placeholder="translations.tcDatePickerPlaceholder"
                  :show-decade-nav="showDecadeNav"
                  :hide-header="hideHeader"
                >
                  <template #button-content style="padding: 12px">
                    <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                  </template>
                </b-form-datepicker>
              </div>
            </b-form-group>
          </b-form>
        </div>
      </section>
      <section class="section-3 bg-block mb-4" v-if="this.visit.location_org_key">
        <header class="mb-3">
          <h2 class="font-style-1">{{ translations.tcProducts }}</h2>
        </header>
        <b-form-select
          @change="setProducts($event)"
          :options="optionProducts"
          class="form-control col-left-input g-select flex-0 mr-3"
        >
          <template slot="first">
            <option :value="null" disabled>{{ translations.tcSelectProduct }}</option>
          </template>
        </b-form-select>

        <div class="g-row mb-0 mb-sm-3" v-for="(visit, index) in this.activePlacements" :key="index" :obj="visit">
          <div class="g-col-1 mb-3 mb-sm-0 d-flex align-items-center">
            <span class="flex-0">
              <strong>{{ visit.product_name }}</strong>
            </span>
            <b-form-input
              :id="'typenumber' + index"
              v-model="visit.quantity_placed"
              class="flex-0 ml-3"
              type="number"
              min="1"
            ></b-form-input>
            <b-button
              variant="tertiary"
              @click="removeProduct(visit.prd_key)"
              class="flex-1 ml-3 d-block w-100-sd btn-pl-xsm btn-text-sm"
              >{{ translations.tcRemove }}</b-button
            >
          </div>
          <hr />
        </div>
      </section>
      <section class="section-3b bg-block mb-4" v-if="this.visit.location_org_key">
        <header class="mb-3">
          <h2 class="font-style-1">{{ translations.tcDetails }}</h2>
        </header>
        <div class="body">
          <b-form>
            <div class="row">
              <div class="col-6">
                <b-form-group class="form-element">
                  <div class="date-select-1 mb-1">
                    <h3 class="font-style-2">{{ translations.tcContactedBy }} *</h3>
                  </div>
                  <div class="g-row mb-0 mb-sm-3">
                    <div class="g-col-1 d-flex mb-3 mb-sm-0">
                      <b-form-select
                        :title="`${translations.tcContactedBy}`"
                        v-model="visit.cord_ind_key"
                        :options="contactOption"
                        class="g-select flex-0 mr-3 mb-3"
                        required
                      ></b-form-select>
                      <b-button
                        :name="`contactedByFindButton`"
                        variant="primary"
                        class="flex-1 btn-pl-xsm"
                        @click="showModal($event, 'personSearchModal')"
                        >{{ translations.tcFind }}</b-button
                      >
                      <template slot="first">
                        <option :value="null" disabled>{{ translations.tcSelect }}</option>
                      </template>
                    </div>
                  </div>
                </b-form-group>
              </div>
            </div>
          </b-form>
        </div>
      </section>
      <section class="section-4 bg-block mb-4" v-if="this.visit.location_org_key">
        <header class="mb-3">
          <h2 class="font-style-1">{{ translations.tcOtherInformation }}</h2>
        </header>
        <div class="body">
          <textarea name="other" v-model="visit.note" class="w-100"></textarea>
        </div>
      </section>
      <section class="section-5 pt-3 pl-0 pl-sm-4" v-if="this.visit.location_org_key">
        <div class="body">
          <div class="d-flex">
            <b-button variant="primary" @click="handleSaveClick" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">{{
              translations.tcSave
            }}</b-button>
            <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">{{
              translations.tcCancel
            }}</b-button>
          </div>
        </div>
      </section>
    </page-body>
    <PersonSearch
      @selectionMade="applyFoundPerson($event)"
      :camp_country="camp_country"
      :modal_title="modal_title"
    ></PersonSearch>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import AppDatePicker from '@/components/AppDatePicker.vue'
import constantData from '@/json/data.json'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import PersonSearch from '@/components/PersonSearch.vue'
import QuickJump from '@/components/quick-jump/QuickJumpGideonCards.vue'
import RestockingProductSelect from '@/components/RestockingProductSelect.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: 'card_restocking_visit_add',
  mixins: [translationMixin],
  data() {
    return {
      showDecadeNav: true,
      hideHeader: true,
      translations: {},
      findButton: '',
      selectChurch: [],
      searchedContact: [],
      productList: [],
      contactOption: [],
      errorMessages: [],
      visit: {
        host_org_key: '',
        date: null,
        mts_key: '',
        location_org_key: null,
        cord_ind_key: '',
        placements: [],
        note: '',
        mtg_key: '',
      },
      camp_country: constantData.USA_country,
      modal_title: 'Individual Search',
    }
  },
  methods: {
    ...mapActions({
      clearFacilityDetails: 'card/clearFacilityDetails',
      clearRestockingVisitDetails: 'card/clearRestockingVisitDetails',
      loadMembersByCamp: 'membership/loadMembersByCamp',
      loadProfile: 'card/getProfile',
      retrieveMeetingTypes: 'card/retrieveMeetingTypes',
      retrieveRestockingVisitDetails: 'card/retrieveRestockingVisitDetails',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedVisitKey: 'churchMinistry/setSelectedVisitKey',
      setSelectedFacilityKey: 'user/setSelectedFacilityKey',
      upsertRestockingVisit: 'card/upsertRestockingVisit',
    }),
    showModal(e, modalName) {
      this.findButton = !e.target ? e : e.target.name
      switch (this.findButton) {
        case 'contactedByFindButton':
          this.modal_title = this.translations.tcContactedBySearch
          break
        default:
          this.modal_title = this.translations.tcIndividualSearch
          break
      }
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    applyFoundPerson($event) {
      this.visit.cord_ind_key = $event.ind_key
      let option = { value: $event.ind_key, text: $event.name_text }
      this.contactOption.push(option)
    },
    setProducts(data) {
      const index = this.visit.placements.findIndex((p) => p.prd_key === data)
      if (index >= 0 && this.visit.placements[index].delete === true) {
        // They may have deleted a previously added item,
        // put it back in the list.
        this.visit.placements[index].delete = false
        this.visit.placements[index].quantity_placed = 0
      } else if (index < 0) {
        let product = this.productList.find((p) => p.prd_key === data)
        this.visit.placements.push({
          plc_key: product.plc_key,
          prd_key: product.prd_key,
          product_name: product.product_name,
          quantity_placed: 0,
          delete: false,
        })
      }
    },
    removeProduct(pkey) {
      const index = this.visit.placements.findIndex((p) => p.prd_key === pkey)
      if (index >= 0) {
        this.visit.placements[index].delete = true
      }
    },
    validate() {
      this.errorMessages = []
      if (this.visit.cord_ind_key == '' || this.visit.date == null) {
        this.errorMessages.push(
          `${this.translations.tcDate} ${this.translations.tcAnd} ${this.translations.tcContactedBy} ${this.translations.tcAreRequiredMessage}`
        )
      }
      let badQuantity = this.visit.placements.find((el) => el.quantity_placed < 1)
      if (badQuantity) {
        this.errorMessages.push(this.translations.tcIncorrectQuantityMessage)
      }
      if (this.errorMessages.length <= 0) {
        return true
      }
      return false
    },
    handleSaveClick() {
      if (!this.validate()) {
        this.$swal({
          icon: 'error',
          confirmButtonText: this.translations.tcOk,
          text: this.errorMessages.join(', '),
        })
        return
      }
      let data = this.visit
      data.host_org_key = this.userOrgKey
      data.member_key = this.userId

      let options = this.meetingTypes.map((type) => {
        return { value: type.mts_key, text: type.description }
      })
      let today = new Date()
      let visdate = new Date(this.visit.date)
      if (this.visit.date === '' || this.visit.date === null || visdate > today) {
        if (options.length > 0) {
          this.visit.mts_key = options.find((item) => item.text.toLowerCase().indexOf('pending') > -1).value
        }
      } else {
        this.visit.mts_key = options.find((item) => item.text.toLowerCase() === 'complete').value
      }

      this.submitRequest(data)
    },
    handleCancelClick() {
      this.$router.push('/programs/gc/profile/card_restocking_visits_list')
    },
    async submitRequest(data) {
      try {
        this.setLoadingStatus(true)
        let response = await this.upsertRestockingVisit(data)
        if (response != '') {
          this.$router.push('/programs/gc/profile/card_restocking_visits_list')
          return
        }
      } catch (error) {
        this.$swal({
          icon: 'error',
          confirmButtonText: this.translations.tcOk,
          text: this.translations.tcUpdateRestockingVisitErrorMessage,
        })
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async handleFacilityChange() {
      this.clearFacilityDetails()
      this.setSelectedVisitKey(null)
      this.clearRestockingVisitDetails()
      await this.loadProfile(this.userSelectedFacilityKey).then(() => {
        this.pageLoad()
      })
    },
    async showFacilityDropdown() {
      this.clearFacilityDetails()
      this.setSelectedVisitKey(null)
      this.clearRestockingVisitDetails()
      this.setSelectedFacilityKey(null)
      this.visit = {
        host_org_key: '',
        date: null,
        mts_key: '',
        location_org_key: '',
        cord_ind_key: '',
        placements: [],
        note: '',
        mtg_key: '',
      }
    },
    async pageLoad() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          await this.retrieveMeetingTypes(),
          await this.loadMembersByCamp(this.userOrgKey),
          await this.loadProfile(this.userSelectedFacilityKey),
          await this.retrieveRestockingVisitDetails(this.userSelectedVisitKey),
          await this.getViewTranslations('buttons', 'forms'),
          await this.getComponentTranslations('quick-jump-gideon-cards'),
        ]).then((results) => {
          const componentTranslations = results[5]
          this.$set(this.translations, 'components', componentTranslations)

          this.productList = this.restockingVisitDetails.list
          this.visit.placements = !!this.restockingVisitDetails.list
            ? this.restockingVisitDetails.list
                .filter((data) => data.plc_quantity_placed != null)
                .map((data) => {
                  return {
                    plc_key: data.plc_key,
                    prd_key: data.prd_key,
                    product_name: data.product_name,
                    quantity_placed: data.plc_quantity_placed,
                    delete: data.plc_delete_flag,
                  }
                })
            : []
          this.contactOption = this.members.map((m) => {
            return {
              text: m.name,
              value: m.ind_key,
            }
          })
          if (this.restockingVisitDetails.meeting != null) {
            this.visit.mtg_key = this.restockingVisitDetails.meeting.mtg_key
            this.visit.date = this.restockingVisitDetails.meeting.mtg_start_date
            this.visit.mts_key = this.restockingVisitDetails.meeting.mtg_mts_key
            this.visit.note = this.restockingVisitDetails.meeting.mtg_note
            this.visit.cord_ind_key = this.restockingVisitDetails.meeting.mtg_coordinator_ind_key
            let option = {
              value: this.restockingVisitDetails.meeting.mtg_coordinator_ind_key,
              text: this.restockingVisitDetails.coordinator.formal_name,
            }
            this.contactOption.push(option)
            this.visit.cord_ind_key = this.restockingVisitDetails.meeting.mtg_coordinator_ind_key
          }
          this.visit.location_org_key = this.userSelectedFacilityKey
          this.camp_country = this.restockingVisitDetails.facility.address_cnt_key
          this.setLoadingStatus(false)
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
  },
  async created() {
    this.pageLoad()
  },
  computed: {
    ...mapGetters({
      convertedAddressFormOptions: 'membership/convertedAddressFormOptions',
      location: 'card/getFacilitySummary',
      members: 'membership/members',
      meetingTypes: 'card/getMeetingTypes',
      prefCulture: 'user/userPreferredCulture',
      restockingVisitDetails: 'card/getRestockingVisitDetails',
      userId: 'user/userId',
      userOrgKey: 'user/userOrgKey',
      userSelectedChurchKey: 'user/userSelectedChurchKey',
      userSelectedFacilityKey: 'user/userSelectedFacilityKey',
      userSelectedVisitKey: 'churchMinistry/selected_visit_key',
    }),
    optionMeetingTypes() {
      let options = this.meetingTypes.map((type) => {
        return { value: type.mts_key, text: type.description }
      })
      let today = new Date()
      let visdate = new Date(this.visit.date)
      if (this.visit.date === '' || this.visit.date === null || visdate > today) {
        if (options.length > 0) {
          this.visit.mts_key = options.find((item) => item.text.toLowerCase().indexOf('pending') > -1).value
        }
        return options.filter((item) => {
          return item.text.toLowerCase().indexOf('pending') > -1
        })
      } else {
        this.visit.mts_key = options.find((item) => item.text.toLowerCase() === 'complete').value
        return options.filter((item) => {
          return item.text.toLowerCase() == 'complete'
        })
      }
    },
    optionProducts() {
      return this.productList.map((p) => {
        return {
          value: p.prd_key,
          text: p.product_name,
        }
      })
    },
    activePlacements() {
      return this.visit.placements.filter((p) => p.delete === false)
    },
  },
  components: {
    AppDatePicker: AppDatePicker,
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    PersonSearch: PersonSearch,
    QuickJump: QuickJump,
    RestockingProductSelect: RestockingProductSelect,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;

  @include breakpoint(sm) {
    width: 24px;
  }

  input {
    order: 2;
  }

  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;

    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}
.date-select-1 {
  width: 45%;
  @include breakpoint(sm) {
    width: 100%;
  }
  .start-input {
    width: 287px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;

  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
